import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`USA Today's Snapshot today used information from PayPerks to show how much a company could save by converting 10 employees to electronic payment annually.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "151.31578947368422%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdhNdiaBSCMUSjg0r//EABkQAAIDAQAAAAAAAAAAAAAAAAECABESA//aAAgBAQABBQJzYQZYGw16GpqUC2Vjkq1dNK7QC5//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BK//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB0QAAIBBAMAAAAAAAAAAAAAAAABEQIQMZEhInH/2gAIAQEABj8CdMPRMPRi2LcmI8ITY2qjskxs/8QAHhAAAgICAgMAAAAAAAAAAAAAAAERIUFREHExgZH/2gAIAQEAAT8hyh1IVoJupLsabvZBfgJtL4OhSkpgU1exBMp7GtFvHCJDsyz/2gAMAwEAAgADAAAAEMAu8v/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QydUQt//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QI8h//8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBsWFR/9oACAEBAAE/EDW0mFGGg0R05n3P3LkMcRoAqKi5LVrtrkuy+ODQDaRdrDxxRoHW/wAYjCgbQp84wPNSEags6ytRO0UfM/Y7C8EA8z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c45e42637a9604b634c47a56ae742af1/1cdb2/payperks_usatodayclip_20100917.webp 380w"],
            "sizes": "(max-width: 380px) 100vw, 380px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c45e42637a9604b634c47a56ae742af1/0246a/payperks_usatodayclip_20100917.jpg 380w"],
            "sizes": "(max-width: 380px) 100vw, 380px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c45e42637a9604b634c47a56ae742af1/0246a/payperks_usatodayclip_20100917.jpg",
            "alt": "payperks usatodayclip 20100917",
            "title": "payperks usatodayclip 20100917",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      